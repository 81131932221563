import { batch } from 'react-redux';

import { CommonService, ReduxService } from 'dashboard-services';
import { globalActions, loginActions } from 'primary-components';

import { basicTimeseriesActions, listTimeseriesActions } from 'actions/timeseries';
import { basicUploadActions } from 'actions/upload';
import properties from 'resources/constants/properties.json';
import { routing } from 'routing';
import { ExcelUtils, GAUtils, TimeSeriesUtils } from 'utils';

const FUNCTIONS_NAMESPACE = properties.functionsNamespace
const DASHBOARD_URL = properties.baseUrl.dashboardUrl

if (module.hot) {
  module.hot.accept();
} 

const RefreshCommands = (() => {

  const refreshCell = async e => {
    try {
      GAUtils.sendEvent({
        category: "view_item",
        action: "RefreshCell",
        label: "command"
      })
      await Excel.run(async context => {
        const cell = context.workbook.getActiveCell();
        cell.calculate();
        await context.sync();
      });
    } catch (error) {
      console.error(error);
    }
    e.completed();
  }

  const refreshSheet = async e => {
    try {
      GAUtils.sendEvent({
        category: "view_item",
        action: "RefreshSheet",
        label: "command"
      })
      await Excel.run(async context => {
        const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
        await ExcelUtils.refreshAllFormulasInSheet({ worksheet: activeWorksheet, context })
      });
    } catch (error) {
      console.error(error);
    }
    e.completed();
  }

  const refreshAll = async e => {
    try {
      GAUtils.sendEvent({
        category: "view_item",
        action: "RefreshAll",
        label: "command"
      })
      await Excel.run(async context => {
        await ExcelUtils.refreshAllFormulas({ context })
      });
    } catch (error) {
      console.error(error);
    }
    e.completed();
  }

  return {
    refreshCell,
    refreshSheet,
    refreshAll
  }
})()

const AuthCommands = (() => {

  const logOut = async e => {
    try {
      window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(loginActions.logOut(false))
    } catch (error) {
      console.error(error)
    }
    e.completed()
  }

  return {
    logOut
  }
})()


const NavigateCommands = (() => {

  const openDocumentation = async e => {
    try {
      CommonService.openInNewTab(`${DASHBOARD_URL}${routing.EXTERNAL.documentation.url}`)
    } catch (error) {
      console.error(error)
    }
    e.completed()
  }

  const openDataLake = async e => {
    ExcelUtils.openTaskPaneComponent(routing.LAKE.name)
    e.completed()
  }
  const openTimeSeries = async e => {
    ExcelUtils.openTaskPaneComponent(routing.TS.name)
    e.completed()
  }
  const openLogin = async e => {
    ExcelUtils.openTaskPaneComponent(routing.LOGIN.name)
    e.completed()
  }
  const openSettings = async e => {
    ExcelUtils.openTaskPaneComponent(routing.SETTINGS.name)
    e.completed()
  }
  const openUpload = async e => {
    ExcelUtils.openTaskPaneComponent(routing.UPLOAD.name)
    e.completed()
  }

  return {
    openDocumentation,
    openDataLake,
    openTimeSeries,
    openLogin,
    openSettings,
    openUpload
  }
})()

const TimeSeriesCommands = (() => {
  const editTimeSeries = async e => {
    try {
      GAUtils.sendEvent({
        category: "view_item",
        action: "TimeSeriesCommands.editTimeSeries",
        label: "command"
      })
      await Excel.run(async context => {
        const cell = context.workbook.getActiveCell();
        cell.load("address")
        cell.load("formulas")
        await context.sync()
        const formula = cell.formulas?.[0]?.[0]
              const parsed = TimeSeriesUtils.validateTable({
                cell,
                formula,
                returnItem: true
              })
              if(parsed) {
                ExcelUtils.openTaskPaneComponent(routing.TS.name)
                const dispatch =  window["ngStore" + FUNCTIONS_NAMESPACE].dispatch,
                      groupedParams = parsed.parsedParameters.getGroupedParams()
                batch(() => {
                  dispatch(listTimeseriesActions.editFormula({ 
                    address: parsed.address, 
                    keys: groupedParams.map(gp => ReduxService.updateObject(gp, { columns: gp.columns?.split(",") })),
                    params: parsed.parsedParameters
                  }))
                  dispatch(basicTimeseriesActions.onChange("ADD NEW", "activeTab"))
                })
              }
        await context.sync()
      });
    } catch (error) {
      console.error(error)
    }
    e.completed()
  }

  return {
    editTimeSeries
  }
})()

const UploadCommands = (() => {

  const selectRange = async e => {
    try {
      GAUtils.sendEvent({
        category: "view_item",
        action: "UploadCommands.selectRange",
        label: "command"
      })
      ExcelUtils.openTaskPaneComponent(routing.UPLOAD.name)
      await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(basicUploadActions.defineFileRange())
    } catch (error) {
      console.error(error)
    }
    e.completed()
  }

  return {
    selectRange
  }
})()

const TableCommands = (() => {

  const clearTable = async e => {
    try {
      GAUtils.sendEvent({
        category: "view_item",
        action: "TableCommands.clearTable",
        label: "command"
      })

      ExcelUtils.clearActiveRangeTable()
    } catch (error) {
      window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore())?.error(error.message)
      console.error(error)
    }
    e.completed()
  }

  return {
    clearTable
  }
})()

Office.onReady(() => {
  Office.actions.associate("RefreshCommands.refreshCell", RefreshCommands.refreshCell);
  Office.actions.associate("RefreshCommands.refreshSheet", RefreshCommands.refreshSheet);
  Office.actions.associate("RefreshCommands.refreshAll", RefreshCommands.refreshAll);
  Office.actions.associate("AuthCommands.logOut", AuthCommands.logOut);
  Office.actions.associate("NavigateCommands.openDocumentation", NavigateCommands.openDocumentation);
  Office.actions.associate("NavigateCommands.openDataLake", NavigateCommands.openDataLake);
  Office.actions.associate("NavigateCommands.openTimeSeries", NavigateCommands.openTimeSeries);
  Office.actions.associate("NavigateCommands.openLogin", NavigateCommands.openLogin);
  Office.actions.associate("NavigateCommands.openSettings", NavigateCommands.openSettings);
  Office.actions.associate("NavigateCommands.openUpload", NavigateCommands.openUpload);
  Office.actions.associate("TimeSeriesCommands.editTimeSeries", TimeSeriesCommands.editTimeSeries);
  Office.actions.associate("UploadCommands.selectRange", UploadCommands.selectRange);
  Office.actions.associate("TableCommands.clearTable", TableCommands.clearTable);
})