import { AuthApi } from 'dashboard-services';
import { globalActions, loginActions } from 'primary-components';

import getApiConfig from 'api/ApiConfig';
import properties from 'resources/constants/properties.json';
import { routing } from 'routing';

import AuthUtils from './AuthUtils';
import ExcelUtils from './ExcelUtils';
import LocalStorageUtils from './LocalStorageUtils';

const FUNCTIONS_NAMESPACE = properties.functionsNamespace

export const AuthSingleton = (() => {
  let instance; //prevent modification of "instance" variable
  function Singleton() {
    if (instance) {
      return instance;
    }
    instance = this;
    instance.isGaInited = undefined;
    instance.onFetchingChange = () => {};

    instance.getApiUrl = () => LocalStorageUtils.getApiUrl()

    instance.refreshNGToken = (() => {
      const refreshToken = localStorage?.getItem("ng-ref-token")
      const token = window["ngStore" + FUNCTIONS_NAMESPACE]?.getState()?.primaryComponentsState?.loginState?.token
      if(refreshToken && !token) {
        return new AuthApi(window["ngStore" + FUNCTIONS_NAMESPACE]?.dispatch(getApiConfig()))
          .refreshToken(refreshToken, true)
          .withErrorAction(() => {
            setTimeout(() => {
              ExcelUtils.openTaskPaneComponent(routing.LOGIN.name)
              window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore())?.info("Please login before using NG Add-in")
              window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(getApiConfig()).onLogOut()
              throw new Error("Please login before using NG custom functions")
            }, 100)
          })
          .noErrorMessage()
          .build()
          .call()
          .then(response => {
            if(AuthUtils.hasAccessToExcel({ uiEntitlements: response.uiEntitlements })) {
              localStorage?.setItem("ng-ref-token", response.refreshToken)
              window["ngStore" + FUNCTIONS_NAMESPACE]?.dispatch(loginActions.loginResponseReducer(response.token, window["ngStore" + FUNCTIONS_NAMESPACE]?.getState()?.globalState?.loginState?.source))
              const id = document.body.id;
              if(id === routing.LOGIN.name) {
                Office.addin.hide()
              }
              return response.token
            } else {
              window["ngStore" + FUNCTIONS_NAMESPACE]?.dispatch(globalActions.getMessageStore())?.info("User does not have permission to use Excel Add-in")
              window["ngStore" + FUNCTIONS_NAMESPACE]?.dispatch(getApiConfig()).onLogOut()
              throw new Error("User does not have permission to use Excel Add-in")
            }
          })
      } else if(!refreshToken && !token) {
        return Promise.resolve().then(() => {
          setTimeout(() => {
            ExcelUtils.openTaskPaneComponent(routing.LOGIN.name)
            window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore())?.info("Please login before using NG Add-in")
            window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(getApiConfig()).onLogOut()
          }, 100)
        })
      }
      return Promise.resolve()
    })()
  }

  Singleton.getInstance = function () {
      return instance || new Singleton();
  }
  return Singleton;
})()