import { CommonService } from 'dashboard-services';

export class Parameters {
  _parameters = {}
  _splittedUserParameters = {}
  _groupedParams = []

  constructor(builder) {
    this._parameters = builder._parameters
    // set up user parameters
    const groupedParamsObject = {}
    let currentIndex = -1;
    builder._userParameters.forEach(userParameter => {
      const splittedRawParam = String(userParameter).split("="),
            key = splittedRawParam[0],
            value = splittedRawParam[1]
      if(!key) {
        throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, `Invalid syntax of optional parameter. Syntax: paramName=paramValue`)
      }
      let resolvedValue = this._parameters[key]?.defaultValue
      if(value !== null && String(value).trim() !== "") {
        resolvedValue = value
      }
      if(CommonService.isJson(value.slice(1, -1))) {
        resolvedValue = CommonService.parseJson(value.slice(1, -1), {});
      }
      if(builder._baseIndexOn !== undefined && builder._baseIndexOn === key) {
        currentIndex++;
      }
      if(builder._groupingParams?.length > 0 && builder._groupingParams.includes(key)) {
        if(!groupedParamsObject[key]) {
          groupedParamsObject[key] = [];
        }
        groupedParamsObject[key].push(resolvedValue);
      } else if(builder._groupingWithIndex?.length > 0 && builder._groupingWithIndex.includes(key)) {
        if(!groupedParamsObject[key]) {
          groupedParamsObject[key] = [];  
        }
        groupedParamsObject[key][currentIndex] = resolvedValue;
      } else {
        this._parameters[key] = {
          value: resolvedValue,
          optional: true
        }
      }
    })

    Object.entries(groupedParamsObject).forEach(([k, v]) => {
      v.forEach((value, index) => {
        if(!this._groupedParams[index]) {
          this._groupedParams[index] = {}
        }
        this._groupedParams[index][k] = value;
      })
    })

    // set up getters to all parameters - seperate loop as we will do here more stuff
    Object.entries(builder._parameters).forEach(([name, obj]) => {
      this[name] = obj.value;
    })
  }

  getSplittedParameters() {
    return this._splittedUserParameters;
  }

  getGroupedParams() {
    return this._groupedParams;
  }

  getParameters() {
    return this._parameters;
  }

  static get Builder() {
    class Builder {
      _parameters = {}
      _userParameters = []
      _groupingParams = []
      _baseIndexOn = undefined;
      _groupingWithIndex = [];

      withRequiredParameter = (parameterName, parameterValue) => {
        if(!parameterValue || String(parameterValue).trim() === "") {
          throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, `Parameter ${parameterName} can not be blank!`)
        }
        this._parameters[parameterName] = {
          value: parameterValue,
          optional: false
        };
        return this;
      }

      withOptionalParameter = (parameterName, defaultValue) => {
        this._parameters[parameterName] = {
          defaultValue,
          optional: true
        }
        return this;
      }

      withUserParameters = (userParameters = []) => {
        this._userParameters = userParameters;
        return this;
      }

      withGrouping = groupingParams => {
        this._groupingParams = groupingParams;
        return this;
      }

      withRequiredArrayParameter = (parameterName, parameterValue) => {
        if(!parameterValue || String(parameterValue).trim() === "") {
          throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, `Parameter ${parameterName} can not be blank!`)
        }
        this._parameters[parameterName] = {
          value: String(parameterValue).split(","),
          optional: false
        }
        return this;
      }

      withRequiredJsonArrayParameter = (parameterName, parameterValue) => {
        if(!parameterValue || String(parameterValue).trim() === "") {
          throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, `Parameter ${parameterName} can not be blank!`)
        }
        if(!CommonService.isJson(parameterValue)) {
          throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, `Parameter ${parameterName} has to be a proper json array!`)
        }
        this._parameters[parameterName] = {
          value: CommonService.parseJson(parameterValue, []),
          optional: false
        }
        return this;
      }

      withBaseIndexOn = index => {
        this._baseIndexOn = index;
        return this;
      }

      withGroupingWithIndex = groupingWithIndex => {
        this._groupingWithIndex = groupingWithIndex;
        return this;
      }

      build = () => new Parameters(this)
    }
    return Builder;
  }
}